export const projectIdsToIgnore = (): string[] => {
    return [
        "efe46492-7d80-11ee-a828-b272f436559e",
        "bbf3f04c-1712-11ed-9b6c-a2fbb71f9295",
    ]
}

export const checkProjectIdInProjectIdsToIgnore = (projectId: string | undefined): boolean => {
    if (!projectId) return false;
    return projectIdsToIgnore().indexOf(projectId.trim()) >= 0;
}

export const getNewOnsiteProjectUrl = (projectId: string): string => {
    return `https://appv2.onsite.no/onsite/projects/${projectId}`;
}
